<script>
  export let title;
  export let url;
</script>

<style>
  a {
    overflow: hidden;
    border-radius: 13px;
  }
  img {
    border-radius: 13px;
    height: 95px;
  }

  @media screen and (max-width: 426px) {
    img {
      height: auto;
      width: 95%;
    }
  }
</style>

<a href={url} target="_blank" alt={title}
  ><img alt="Get it on Google Play" src="images/google-play-badge.png" /></a>
