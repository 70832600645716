<script>
  import SocialLinks from './SocialLinks.svelte';
</script>

<style>
  footer {
    background-color: #262626;
    color: white;
    border-top: 1px solid white;
    padding-bottom: 1.2rem;
  }
  footer > div.inner {
    max-width: 64rem;
    margin: 1rem auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 25rem 6rem;
    gap: 1px 1px;
    grid-template-areas: 'left-col right-col';
    justify-items: stretch;
    height: 100%;
    background-color: #262626;
    padding: 0.8rem;
  }
  .left-col {
    grid-area: left-col;
    display: flex;
    align-items: start;
    justify-content: center;
    flex-direction: column;
  }
  .left-col img {
    width: 100%;
    max-width: 12rem;
    padding-bottom: 2rem;
  }
  .social {
    margin-left: 0;
  }
  .right-col {
    grid-area: right-col;
    padding-bottom: 1rem;
    font-size: 0.8rem;
    text-align: center;
    height: 320px;
  }

  @media screen and (max-width: 640px) {
    footer > div.inner {
      padding-left: 1rem;
      padding-right: 1rem;
      grid-template-columns: 1fr;
      grid-template-rows: auto;
      gap: 1px 1px;
      grid-template-areas: 'left-col' 'right-col';
      justify-items: start;
    }
    .right-col {
      width: 100%;
    }
    .social {
      margin-left: 0;
    }
  }
</style>
<svelte:head>
  <script type="text/javascript">(function (w,d) {var loader = function () {var s = d.createElement("script"), tag = d.getElementsByTagName("script")[0]; s.src="https://cdn.iubenda.com/iubenda.js"; tag.parentNode.insertBefore(s,tag);}; if(w.addEventListener){w.addEventListener("load", loader, false);}else if(w.attachEvent){w.attachEvent("onload", loader);}else{w.onload = loader;}})(window, document);</script>
</svelte:head>
<footer>
  <div class="inner">
    <div class="left-col">
      <img alt="Logo" src="./images/logo.svg" />
      
      <a href="https://www.iubenda.com/privacy-policy/15739380" class="iubenda-white iubenda-noiframe iubenda-embed iubenda-noiframe " title="Privacy Policy">
        Privacy Policy
      </a>
      <div class="social">
        <SocialLinks showLabels={true} />
      </div>
    </div>
    <div class="right-col" id="map">
      <!-- svelte-ignore a11y-missing-attribute -->
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.8295712246704!2d29.03487111552683!3d41.00710332744582!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cab80574fd9419%3A0x5cfd52387b55ff29!2zS2_Fn3V5b2x1LCBBc21hZGFsxLEgU2suIE5vOjI4LCAzNDcxOCBLYWTEsWvDtnkvxLBzdGFuYnVs!5e0!3m2!1str!2str!4v1650558090393!5m2!1str!2str" width="100%" height="450" style="border:0;width:100%;" allowfullscreen="false" loading="lazy" referrerpolicy="no-referrer-when-downgrade" />
    </div>
  </div>
</footer>

