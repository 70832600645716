<script>
  import SocialLinks from './SocialLinks.svelte';
  import { scrollto } from 'svelte-scrollto';
</script>

<style>
  header {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #1b1b28;
    background-image: linear-gradient(
      0deg,
      rgba(35, 36, 0, 1) 0%,
      rgba(121, 68, 9, 1) 37%,
      rgba(164, 5, 176, 1) 63%,
      rgba(255, 216, 0, 1) 100%
    );
    background-image: url('/images/HeaderBg.png');
    min-height: 100vh;
  }

  header > div {
    max-width: 64rem;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .splash_icon {
    margin: 7rem auto 1rem auto;
    width: 70%;
    -webkit-filter: drop-shadow(0 8px 6px rgba(0, 0, 0, 0.24));
    filter: drop-shadow(0 8px 6px rgba(0, 0, 0, 0.24));
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2rem auto;
  }

  nav button {
    border-radius: 4px;
    background-color: #f4f4f4;
    border: none;
    color: #181a26;
    text-align: center;
    transition: all 0.5s;
    cursor: pointer;
    padding: 1.2rem 2.8rem;
    margin: 0.6rem;
    font-size: 1.6rem;
    min-width: 12rem;
  }

  button:hover,
  button:active {
    transform: scale(1.12);
    background-color: #ffbc00;
    color: white;
  }

  .scroll-icon,
  .scroll-icon:active,
  .scroll-icon:focus,
  .scroll-icon:hover {
    position: absolute;
    bottom: 2rem;
    font-size: 2rem;
    color: white;
    transition: all 0.4s ease;
    animation: pulse 2s infinite;
    background-color: transparent;
    border: none;
  }

  @keyframes pulse {
    0% {
      transform: scale(0.95);
    }

    70% {
      transform: scale(1);
    }

    100% {
      transform: scale(0.95);
    }
  }

  @media screen and (max-width: 768px) {
    header {
      max-width: 100%;
    }
    .splash_icon {
      width: 75%;
      margin: 2rem auto;
    }
    nav button {
      max-width: 6rem;
    }
  }

  @media screen and (max-width: 426px) {
    header {
      min-height: inherit;
    }
    .splash_icon {
      width: 65%;
    }
    nav {
      display: block;
    }
  }
</style>

<header>
  <div>
    <img alt="Logo" src="./images/logo.svg" class="splash_icon" />
    <nav>
      <button class="games" use:scrollto={'#games'}>Games</button>
      <button class="team" use:scrollto={'#team'}>Team</button>
      <button class="contact" use:scrollto={'#contact'}>Contact</button>
    </nav>
    <div class="social">
      <SocialLinks showLabels={false} />
    </div>
    <button class="scroll-icon" use:scrollto={'#games'}>
      <span class="fa fa-chevron-down" />
    </button>
  </div>
</header>
