<script>
  function handleContact(subject) {
    window.location = `mailto:jobs@reboot.ist?subject=Job Application for ${subject}&body=Job Application for ${subject}`;
  }
</script>

<style>
  section {
    margin-bottom: 6rem;
  }
  h1 {
    font-weight: 800;
    font-size: 5rem;
    color: #f76be5;
    margin-bottom: 0;
  }
  h3 {
    font-weight: normal;
    font-size: 2.4rem;
    text-align: center;
  }
  ul {
    text-align: left;
    list-style: none;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    margin: 0;
    padding: 0.8rem;
  }
  ul li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin: 1rem;
    padding: 1.4rem 2.4rem;
    border-radius: 12px;
    background-color: #ffff00;
    width: 6rem;
    font-size: 1.4rem;
    cursor: pointer;
    transition: all 0.4s ease;
    transform: scale(1);
  }
  ul li:hover {
    transform: scale(1.2);
  }
  p {
    font-size: 1.4rem;
    text-align: center;
    max-width: 80%;
    text-align: center;
    margin: 0 auto;
  }
  button {
    font-size: 2.4rem;
    color: #f76be4;
    font-weight: 800;
    text-decoration: none;
    transition: all 0.35s ease;
    background-color: transparent;
    border: none;
    text-align: center;
    margin: 0 auto;
    display: inline-block;
  }
  button:hover,
  button:active,
  button:visited {
    color: #99ff00;
    text-decoration: none;
  }
  .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  @media screen and (max-width: 640px) {
    ul {
      display: block;
    }
    ul li {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    h1 {
      text-align: center;
    }
  }

  @media screen and (max-width: 1024px) {
    .inner {
      display: block;
    }
    ul li {
      margin-left: auto;
      margin-right: auto;
      max-width: 100%;
    }
    button {
      display: block;
    }
  }
</style>

<section id="contact">
  <div class="row">
    <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4">
      <h1>WANT TO JOIN US?</h1>
    </div>
    <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8">
      <div class="inner">
        <h3>We are looking for:</h3>
        <ul>
          <li
            on:click={(e) => handleContact('Game Designer')}
            style="background-color: #9eb8e7;">Game Designer</li>
          <li
            on:click={(e) => handleContact('Game Developer')}
            style="background-color: #9ee7e4;">Game Developer</li>
          <li
            on:click={(e) => handleContact('Game Artist')}
            style="background-color: #9ee7a4;">Game Artist</li>
        </ul>
        <p>Contact us at</p>
        <button on:click|preventDefault={(e) => handleContact()}
          >jobs@reboot.ist</button>
      </div>
    </div>
  </div>
</section>
