<script>
  import QMark from '../QMark.svelte';
  import teamData from '../../data/team.json';
</script>

<style>
  section {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;
    max-width: 64rem;
    margin: 2rem auto;
  }

  .team-cell {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2.3fr 0.6fr;
    gap: 4px 4px;
    grid-template-areas:
      '.'
      '.';
    align-items: start;
    justify-items: center;
    padding-bottom: 6rem;
  }

  h3,
  p {
    margin: 0;
    text-align: center;
  }
  img {
    border-radius: 50%;
    text-align: center;
    width: 12rem;
    height: 12rem;
  }
  .team-cell h3 {
    margin-top: 0.8rem;
  }

  @media screen and (max-width: 768px) {
    section {
      grid-template-columns: 1fr 1fr;
      margin: 0;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 426px) {
    section {
      display: block;
    }
  }
</style>

<section id="team" style="padding-top: 5%">
  {#each teamData as member (member.id)}
    <div class="team-cell">
      <img src={member.image} alt="Team Member" />
      <h3>{member.displayName}</h3>
      <p class="team-title">{member.title}</p>
    </div>
  {/each}
  <div class="team-cell">
    <QMark />
    <h3>WANT TO JOIN US?</h3>
    <p class="team-title">Contact Us</p>
  </div>
</section>
