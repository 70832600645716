<script>
  import { onMount } from 'svelte';
  import AppstoreLink from './AppstoreLink.svelte';
  import PlaystoreLink from './PlaystoreLink.svelte';
  import IntersectionObserver from 'svelte-intersection-observer';

  export let id;
  export let title;
  export let description;
  export let image;
  export let appstoreUrl;
  export let playstoreUrl;
  export let overlay;
  export let background;

  export let primaryColor = '#ccc';
  export let bgStartColor = '#fff';
  export let bgEndColor = '#000';

  let intersecting;
  let element;
  let isEven;

  $: {
    isEven = id % 2 === 0;
  }
</script>

<style>
  .grid {
    position: relative;
    display: block;
    margin: 1rem auto;
    border-radius: 3px;
    padding: 0;
    box-shadow: 0px 2px 7px 3px rgba(0, 0, 0, 0.05);
    -webkit-box-shadow: 0px 2px 7px 3px rgba(0, 0, 0, 0.05);
    -moz-box-shadow: 0px 2px 7px 3px rgba(0, 0, 0, 0.05);
    max-width: 75%;
    overflow: hidden;
  }

  .even {
    margin-left: 0;
    border-top-right-radius: 2rem;
    border-bottom-right-radius: 2rem;
  }

  .odd {
    margin-right: 0;
    border-top-left-radius: 2rem;
    border-bottom-left-radius: 2rem;
  }

  .bg {
    opacity: 0.85;
    filter: blur(8px);
    -webkit-filter: blur(8px);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 200;
    overflow: hidden;
    transform: scale(1.25);
  }

  .bg-grad {
    display: block;
    opacity: 0.45;
    width: 100%;
    height: 100%;
  }

  .inner {
    position: relative;
    margin: 0 auto;
    padding: 1.6rem;
    display: grid;
    grid-template-rows: auto;
    gap: 2rem;
    grid-template-areas:
      '. .'
      'left right'
      '. .';
    align-items: stretch;
    max-width: 64rem;
    z-index: 900;
  }

  h1 {
    display: block;
    text-align: left;
    font-weight: 800;
    font-size: 3.2rem;
    color: white;
    text-shadow: 0px 2px #00000054;
  }

  .grid.even .inner {
    grid-template-columns: 1.5fr 0.5fr;
  }
  .grid.odd .inner {
    grid-template-columns: 0.5fr 1.5fr;
  }
  .grid.even .inner,
  .grid.odd .inner {
    max-width: 100%;
  }

  .grid.even .item {
    grid-area: left;
  }
  .grid.even .image {
    grid-area: right;
  }

  .grid.odd .item {
    grid-area: right;
  }
  .grid.odd .image {
    grid-area: left;
  }

  p {
    background-color: #00000054;
    padding: 0.8rem 1.4rem;
    border-radius: 8px;
    text-align: left;
    color: white;
    margin-top: 0;
  }

  .item {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    grid-area: right;
    padding: 2rem;
  }

  .image {
    display: flex;
    align-items: center;
    justify-content: center;
    grid-area: left;
    display: block;
  }

  .image a,
  .image a:hover,
  .image a:active,
  .image a:focus,
  .image a:visited {
    text-decoration: none;
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  .image img {
    display: block;
    margin: 0 auto;
    height: 100%;
    max-height: 16rem;
    border-radius: 72px;
    animation-duration: 1s;
    animation-fill-mode: both;
    border: 4px solid white;
  }

  .overlay {
    position: absolute;
    bottom: 0;
    height: 200px;
    z-index: 800;
  }
  .overlay.even {
    left: 0;
  }
  .overlay.odd {
    right: 0;
  }

  .stores {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  @media screen and (max-width: 1024px) {
    h1 {
      text-align: center;
    }
    .grid.even,
    .grid.odd {
      margin: 2rem auto;
      border-radius: 12px;
      width: 95%;
      max-width: 100%;
    }
    .inner {
      display: block;
    }
    .inner .item {
      display: block;
    }
    .stores {
      text-align: center;
    }
  }

  @media screen and (max-width: 426px) {
    .grid {
      max-width: 100%;
    }
    .grid .inner,
    .stores {
      display: block;
    }
    .overlay {
      height: 200px;
    }
    .image img {
      max-height: 12rem;
      border-radius: 40px;
    }
    h1 {
      font-size: 2rem;
    }
  }
</style>

<IntersectionObserver {element} bind:intersecting rootMargin="10px">
  <div
    bind:this={element}
    class="grid animate__animated"
    class:animate__fadeInLeft={isEven && intersecting}
    class:animate__fadeInRight={!isEven && intersecting}
    class:animate__fadeOutLeft={isEven && !intersecting}
    class:animate__fadeOutRight={!isEven && !intersecting}
    class:even={isEven}
    class:odd={!isEven}>
    <div class="bg" style="background-image: url({background});">
      <div
        class="bg-grad"
        style="background-color: ${bgStartColor};
    background-image: linear-gradient(90deg, {bgStartColor} 0%, {bgEndColor} 100%);" />
    </div>

    <div class="inner">
      <div
        class="image animate__animated animate__delay-1s"
        class:animate__fadeInUp={intersecting}
        class:animate__fadeOutUp={!intersecting}>
        <a href={appstoreUrl} alt={title} target="_blank">
          <img src={image} alt={title} />
        </a>
      </div>
      <div class="item">
        <h1
          style="color: {primaryColor}"
          class="animate__animated animate__delay-1s"
          class:animate__fadeInUp={intersecting}
          class:animate__fadeOutUp={!intersecting}>
          {title}
        </h1>
        <p
          class="animate__animated animate__delay-1s"
          class:animate__fadeInUp={intersecting}
          class:animate__fadeOutUp={!intersecting}>
          {description}
        </p>
        {#if appstoreUrl || playstoreUrl}
          <div
            class="stores animate__animated animate__delay-1s"
            class:animate__fadeInUp={intersecting}
            class:animate__fadeOutUp={!intersecting}>
            {#if appstoreUrl}
              <AppstoreLink {title} url={appstoreUrl} />
            {/if}
            {#if playstoreUrl}
              <PlaystoreLink {title} url={playstoreUrl} />
            {/if}
          </div>
        {/if}
      </div>
    </div>
    {#if overlay}
      <img
        class="overlay animate__animated animate__delay-2s"
        class:even={isEven}
        class:odd={!isEven}
        class:animate__fadeInUp={intersecting}
        class:animate__fadeOutUp={!intersecting}
        src={overlay}
        alt="Overlay" />
    {/if}
  </div>
</IntersectionObserver>
