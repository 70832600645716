<script>
  import Header from './components/Header.svelte';
  import Games from './components/sections/Games.svelte';
  import Team from './components/sections/Team.svelte';
  import Contact from './components/sections/Contact.svelte';
  import Footer from './components/Footer.svelte';
  import { MetaTags } from 'svelte-meta-tags';
</script>

<style>
  main {
    flex: 1;
    margin: 0 auto;
    padding: 0.8rem 1.6rem 4rem 1.6rem;
    max-width: 64rem;
  }

  @media screen and (max-width: 1024px) {
    main {
      width: 100%;
      padding: 0rem;
    }
  }
</style>

<MetaTags
  title="Reboot Interactive"
  description="Reboot Interactive is an Istanbul-based digital interactive studio that aims to create experiences via games and interactive works."
  canonical="https://www.reboot.ist/"
  openGraph={{
    url: 'https://www.reboot.ist/',
    title: 'Reboot Interactive',
    description:
      'Reboot Interactive is an Istanbul-based digital interactive studio that aims to create experiences via games and interactive works.',
    images: [
      {
        url: 'https://www.reboot.ist/rebootinteractive.png',
        width: 1200,
        height: 630,
        alt: 'Reboot Interactive',
      },
    ],
    site_name: 'Reboot Interactive',
  }} />
<Header />

<Games />
<main>
  <Team />
  <Contact />
</main>

<Footer />
