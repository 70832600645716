<style>
  svg {
    width: 192px;
    height: 192px;
  }
</style>

<svg
  version="1.1"
  id="Layer_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 180 180"
  style="enable-background:new 0 0 180 180;"
  xml:space="preserve"
  ><style type="text/css">
    .st0 {
      fill: #ffffff;
    }
    .st1 {
      fill: #ff2736;
    }
  </style><circle class="st0" cx="90" cy="90" r="90" />
  <path
    class="st1"
    d="M77.8,116.7v-3.3c0-10.7,0.8-17.4,2.5-20.2s6-6.8,13.2-12c3.1-2.1,5.7-4.7,7.8-7.7c1.5-2.7,2.3-5.7,2.2-8.8
	c0.2-3.9-1.3-7.6-4.1-10.2c-2.7-2.5-6.3-3.8-10-3.7c-10.4,0-16.2,7.3-17.5,22l-24.1-3.5c1.8-13.2,6.7-23.2,14.5-30
	c8-6.9,18.3-10.5,28.9-10.3c10.6-0.3,21,3.2,29.2,10c7.6,6.2,12,15.6,11.8,25.4c0,12.7-6.5,23.4-19.6,31.9
	c-5.7,3.8-9.1,6.7-10.3,8.8c-1.2,2.1-1.8,6-1.8,11.7L77.8,116.7z M104.8,151.1H77.2v-26.5h27.6V151.1z" />
</svg>
