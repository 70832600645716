<script>
  import Game from '../Game.svelte';
  import GamesJson from '../../data/games.json';
</script>

<section id="games">
  {#each GamesJson as game (game.id)}
    <Game {...game} />
  {/each}
</section>
