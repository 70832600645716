<script>
  import socialData from '../data/social.json';
  import { scrollto } from 'svelte-scrollto';

  export let showLabels;
</script>

<style>
  div {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  a {
    text-decoration: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0.5rem 1.6rem;
    color: white;
  }
  a.has-label {
    margin: 1rem;
  }
  a:hover,
  a:active,
  a:visited {
    text-decoration: none;
  }
  a span {
    font-size: 2rem;
    margin-bottom: 0.8rem;
  }
  .has-padding-top {
    padding-top: 4rem;
  }
</style>

<div class:labels={showLabels} class:has-padding-top={showLabels}>
  {#each socialData as social (social.id)}
    <a href={social.url} target="_blank" class:has-label={showLabels}>
      <span class={social.icon} />
      {#if showLabels}
        {social.title}
      {/if}
    </a>
  {/each}

  {#if !showLabels}
  <a href="#map" use:scrollto={'#map'} target="_blank" class:has-label={showLabels}>
    <span class="fa fa-map-marker-alt" />
    </a>
  {/if}
</div>
