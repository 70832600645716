<script>
  export let title;
  export let url;
</script>

<style>
  a {
    overflow: hidden;
    border-radius: 13px;
  }
  img {
    border-radius: 13px;
    min-width: 12rem;
    height: 60px;
  }

  @media screen and (max-width: 426px) {
    img {
      height: auto;
      width: 80%;
    }
  }
</style>

<a href={url} target="_blank" alt={title}>
  <img src="/images/appstore.svg" alt="Download on the App Store" /></a>
